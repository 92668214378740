nav {
  position: sticky;
  top: 0;
  height: 4em;
  z-index: 999;
  background-color: var(--clr-grey-background);
  padding: 0 15px;
}

.nav-active {
  height: 30em;
}

.stickyHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 95%;
  z-index: 999;
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

a {
  /*color: white;*/
  text-decoration: none;
}
.menu {
  list-style-type: none;
  padding-inline-start: 0;
}
.logo {
  font-size: 20px;
  padding: 7.5px 10px 7.5px 0;
  color: #6c68fb;
  font-weight: 600;
}

.logo-lead {
  color: #6c68fb;
  font-weight: 900;
}

.grey-color {
  color: #868e96;
}

.item {
  padding: 10px;
}

.item a:hover,
.item a:hover::after {
  color: #ccc;
}

.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.menu li a {
  display: block;
  padding: 15px 5px;
  color: black;
  text-decoration: none;
}

.menu li a:hover {
  color: #6c68fb;
  font-weight: 600;
}

.toggle {
  order: 1;
  font-size: 20px;
}

.item {
  order: 3;
  width: 100%;
  text-align: center;
  display: none;
}
.active .item {
  display: block;
}

@media all and (min-width: 1080px) {
  .menu {
    align-items: flex-start;
    flex-wrap: nowrap;
    background: none;
  }
  .logo {
    order: 0;
  }
  .item {
    order: 1;
    position: relative;
    display: block;
    width: auto;
  }

  .toggle {
    display: none;
  }
}
