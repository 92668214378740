/*
=============== 
Variables
===============
*/

:root {
  --clr-primary: #6c68fb;
  --clr-grey-background: #f1f5f8;
  --clr-text-black: #222;
  --spacing: 0.1rem;
}

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-grey-background);
  color: var(--clr-text-black);
  line-height: 1.5;
  font-size: 0.875rem;
  scroll-behavior: smooth;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

/*  global classes */

section {
  /* for sticky hamburger menu with height 3em */
  scroll-margin-top: 5em;
}

section h1 {
  text-align: center;
  font-size: 3em;
  font-weight: 400;
  margin-bottom: 1em;
}

.scroll-burger {
  /* For sticky hamburger menu with height 30em */
  scroll-margin-top: 31em;
}
