.icon-grid {
  display: grid;
  font-size: 3rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  grid-auto-rows: auto;
  height: auto;
}

.skill-icon {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.skill-icon p {
  font-size: 0.8rem;
}

@media (min-width: 1080px) {
  .icon-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
