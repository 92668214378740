.contact-div-main {
  padding: 2em;
  display: flex;
}

.contact-div-main > * {
  flex: 1;
}

.contact-title {
  font-size: 3em;
  font-weight: 400;
  text-align: center;
}

.contact-subtitle {
  font-size: 1em;
  line-height: normal;
  text-align: center;
}

.contact-detail,
.contact-detail-email {
  margin-top: 20px;
  font-size: 2em;
  font-weight: 400;
  color: #868e96;
  text-decoration: none;
  text-align: center;
}

.contact-detail:hover,
.contact-detail-email:hover {
  color: black;
  text-shadow: 2px 1px 2px #b5b5b5;
  transition: all 0.3s;
}

.contact-text-div {
  margin-top: 1.5rem;
  text-align: center;
}

.contact-image-div {
  display: none;
}

/* Media Query */
@media (min-width: 1080px) {
  .contact-div-main {
    margin: 2.5em 0;
    padding: 0 2em;
    display: flex;
  }
  .contact-image-div {
    display: block;
  }
  .contact-image-div > img {
    max-width: 100%;
    height: auto;
    margin-left: 1.5rem;
  }
}
